var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-cog ")]):_c('v-icon',[_vm._v(" mdi-cog ")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('div',{attrs:{"id":"floatingAction"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"btn-1","large":"","color":_vm.mic ? 'green lighten-3' : 'red lighten-3'}},'v-icon',attrs,false),on),[_vm._v("mic")])]}}])},[(_vm.mic)?_c('span',[_vm._v("Mic Connected")]):_c('span',[_vm._v("Mic Not Connected")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"btn-3","large":"","color":_vm.video ? 'green lighten-3' : 'red lighten-3'}},'v-icon',attrs,false),on),[_vm._v("videocam")])]}}])},[(_vm.video)?_c('span',[_vm._v("Camera Connected")]):_c('span',[_vm._v("Camera Not Connected")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"btn-2","large":"","color":_vm.audio ? 'green lighten-3' : 'red lighten-3'}},'v-icon',attrs,false),on),[_vm._v("volume_up")])]}}])},[(_vm.audio)?_c('span',[_vm._v("Audio Connected")]):_c('span',[_vm._v("Audio Not Connected")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"btn-4","large":"","color":_vm.wifi ? 'green lighten-3' : 'red lighten-3'}},'v-icon',attrs,false),on),[_vm._v("wifi")])]}}])},[(_vm.wifi)?_c('span',[_vm._v("Wifi Connected")]):_c('span',[_vm._v("Wifi Not Connected")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }